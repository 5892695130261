import { IntegerField } from '@navinc/base-react-components/wayfinder'

import { FragmentOf, graphql, readFragment } from '../../../lib/cms.js'
import { ValidationError } from '../types.js'

export const numberFragment = graphql(/* GraphQL */ `
  fragment numberFragment on CmsNumberFormField {
    name
    label
    numberDefaultValue: defaultValue
    required
  }
`)

export type NumberFieldProps = {
  data: FragmentOf<typeof numberFragment>
  validationError?: ValidationError
}

export const NumberField = ({ data, validationError }: NumberFieldProps) => {
  const { name, numberDefaultValue, label, required } = readFragment(numberFragment, data)
  return (
    <IntegerField
      name={name ?? undefined}
      label={label}
      defaultValue={numberDefaultValue ?? undefined}
      required={required ?? undefined}
      error={validationError?.data?.find((error) => error.field === name)?.message}
      uncontrolled
    />
  )
}
