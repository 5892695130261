import { Button } from '@navinc/base-react-components/wayfinder'
import { useFetcher } from '@remix-run/react'
import { FragmentOf, readFragment } from 'gql.tada'
import { ReactNode, useState } from 'react'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Gutter } from '../../_components/Gutter/index.js'
import { RichText } from '../../_components/RichText/index.jsx'
import { Leaf } from '../../_components/RichText/serialize.jsx'
import { graphql } from '../../lib/cms.js'
import { clientEnv } from '../../lib/env.js'
import { captureError } from '../../lib/error-reporting.js'
import { logger } from '../../lib/logger/logger.js'
import { action as formAction } from '../../routes/forms.($id)/route.jsx'
import { Checkbox, checkboxFragment } from './Checkbox/index.jsx'
import { Message, messageFragment } from './Message/index.jsx'
import { NumberField, numberFragment } from './NumberField/index.jsx'
import { Select, selectFragment } from './Select/index.jsx'
import { Text, textFragment } from './Text/index.jsx'
import { TextArea, textAreaFragment } from './Textarea/index.jsx'

export const dynamicFormFragment = graphql(
  /* GraphQL */ `
    fragment dynamicFormFragment on CmsForm {
      id
      fields {
        __typename
        ...textFragment
        ...checkboxFragment
        ...numberFragment
        ...messageFragment
        ...selectFragment
        ...textAreaFragment
      }
      submitButtonLabel
    }
  `,
  [textFragment, checkboxFragment, numberFragment, messageFragment, selectFragment, textAreaFragment],
)

export type DynamicFormProps = {
  data: FragmentOf<typeof dynamicFormFragment> | null
}

const formFields = {
  CmsTextFormField: Text,
  CmsCheckboxFormField: Checkbox,
  CmsNumberFormField: NumberField,
  CmsMessageFormField: Message,
  CmsSelectFormField: Select,
  CmsTextAreaFormField: TextArea,
}

const reportedErrorTypenames = new Set<string>()

const fetchCaptchaToken = async (executeRecaptcha: ((action?: string) => Promise<string>) | undefined) => {
  if (!clientEnv.CAPTCHA_SITE_KEY) {
    logger.info('No CAPTCHA_SITE_KEY found, skipping CAPTCHA validation')
    return 'mock-token'
  }

  if (!executeRecaptcha) {
    captureError(new Error('TODO: Recaptcha not initialized'))
    return null
  }

  return await executeRecaptcha('submit')
}

const FormComponent = ({ data }: DynamicFormProps) => {
  const [isExecutingCaptcha, setIsExecutingCaptcha] = useState(false)
  const fetcher = useFetcher<typeof formAction>()
  const { executeRecaptcha } = useGoogleReCaptcha()

  if (!data) {
    return null
  }

  const { id, fields, submitButtonLabel } = readFragment(dynamicFormFragment, data)

  if (!fields || fields.length === 0) {
    return null
  }

  const validationError =
    (fetcher.data &&
      'errors' in fetcher.data &&
      fetcher.data.errors.find((error) => error.extensions?.name === 'ValidationError')?.extensions) ||
    undefined

  const unexpectedError =
    fetcher.data &&
    'errors' in fetcher.data &&
    fetcher.data.errors[0]?.extensions?.name !== 'ValidationError' &&
    fetcher.data.errors[0]?.message

  const nested = () => {
    if (unexpectedError) {
      return <div>{unexpectedError}</div>
    }

    if (fetcher.data && 'form' in fetcher.data) {
      return <RichText content={fetcher.data.form.confirmationMessage as Leaf[]} />
    }

    return (
      <fetcher.Form
        onSubmit={async (e) => {
          e.preventDefault()

          const formData = new FormData(e.currentTarget)

          setIsExecutingCaptcha(true)

          const token = await fetchCaptchaToken(executeRecaptcha)

          setIsExecutingCaptcha(false)

          if (!token) {
            // TODO: IDK what the user experience should be here
            return
          }

          formData.set('recaptchaToken', token)

          fetcher.submit(formData, { method: 'POST', action: `/forms/${id}` })
        }}
        className="flex flex-col gap-300 max-w-[900px] mx-auto"
      >
        {fields.map((field, index) => {
          const Component = formFields[field.__typename]

          if (!Component && !reportedErrorTypenames.has(field.__typename)) {
            reportedErrorTypenames.add(field.__typename)
            captureError(new Error(`Field type ${field.__typename} not supported`))
            return null
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- will need to figure out how to type this properly
          return <Component key={index} data={field as any} validationError={validationError} />
        })}
        <div>
          <Button variant="primary" type="submit" loading={isExecutingCaptcha || fetcher.state === 'submitting'}>
            {submitButtonLabel ?? 'Submit'}
          </Button>
        </div>
      </fetcher.Form>
    )
  }

  return <Gutter className="px-200 py-800 md:py-1000">{nested()}</Gutter>
}

const CaptchaProvider = ({ children }: { children: ReactNode }) => {
  if (!clientEnv.CAPTCHA_SITE_KEY) {
    return children
  }

  return <GoogleReCaptchaProvider reCaptchaKey={clientEnv.CAPTCHA_SITE_KEY}>{children}</GoogleReCaptchaProvider>
}

export const DynamicForm = ({ data }: DynamicFormProps) => {
  return (
    <CaptchaProvider>
      <FormComponent data={data} />
    </CaptchaProvider>
  )
}

export const formBlockFragment = graphql(
  /* GraphQL */ `
    fragment formBlockFragment on CmsFormBlock {
      form {
        ...dynamicFormFragment
      }
    }
  `,
  [dynamicFormFragment],
)

export type FormBlockProps = {
  data: FragmentOf<typeof formBlockFragment>
}

export const FormBlock = ({ data }: FormBlockProps) => {
  const { form } = readFragment(formBlockFragment, data)

  return <DynamicForm data={form} />
}
