import { SimpleSelectField } from '@navinc/base-react-components/wayfinder'

import { FragmentOf, graphql, readFragment } from '../../../lib/cms.js'
import { ValidationError } from '../types.js'

export const selectFragment = graphql(/* GraphQL */ `
  fragment selectFragment on CmsSelectFormField {
    name
    label
    defaultValue
    required
    options {
      label
      value
    }
  }
`)

export type SelectProps = {
  data: FragmentOf<typeof selectFragment>
  validationError?: ValidationError
}

export const Select = ({ data, validationError }: SelectProps) => {
  const { name, defaultValue, label, required, options } = readFragment(selectFragment, data)
  return (
    <SimpleSelectField
      name={name ?? undefined}
      label={label}
      defaultValue={defaultValue ?? undefined}
      required={required ?? undefined}
      error={validationError?.data?.find((error) => error.field === name)?.message}
      options={options as { label: string; value: string }[]}
    />
  )
}
