import { StringField } from '@navinc/base-react-components/wayfinder'

import { FragmentOf, graphql, readFragment } from '../../../lib/cms.js'
import { ValidationError } from '../types.js'

export const textFragment = graphql(/* GraphQL */ `
  fragment textFragment on CmsTextFormField {
    name
    label
    defaultValue
    required
  }
`)

export type TextProps = {
  data: FragmentOf<typeof textFragment>
  validationError?: ValidationError
}

export const Text = ({ data, validationError }: TextProps) => {
  const { name, defaultValue, label, required } = readFragment(textFragment, data)
  return (
    <StringField
      name={name ?? undefined}
      label={label}
      defaultValue={defaultValue ?? undefined}
      required={required ?? undefined}
      error={validationError?.data?.find((error) => error.field === name)?.message}
      uncontrolled
    />
  )
}
