import { RichText } from '../../../_components/RichText/index.jsx'
import { Leaf } from '../../../_components/RichText/serialize.jsx'
import { FragmentOf, graphql, readFragment } from '../../../lib/cms.js'
import { ValidationError } from '../types.js'

export const messageFragment = graphql(/* GraphQL */ `
  fragment messageFragment on CmsMessageFormField {
    message
  }
`)

export type MessageProps = {
  data: FragmentOf<typeof messageFragment>
  validationError?: ValidationError
}

export const Message = ({ data }: MessageProps) => {
  const { message } = readFragment(messageFragment, data)

  return <RichText content={message as Leaf[]} />
}
