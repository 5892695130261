import { Checkbox as WFCheckbox } from '@navinc/base-react-components/wayfinder'
import { useState } from 'react'

import { FragmentOf, graphql, readFragment } from '../../../lib/cms.js'
import { ValidationError } from '../types.js'

export const checkboxFragment = graphql(/* GraphQL */ `
  fragment checkboxFragment on CmsCheckboxFormField {
    name
    label
    boolDefaultValue: defaultValue
  }
`)

export type CheckboxProps = {
  data: FragmentOf<typeof checkboxFragment>
  validationError?: ValidationError
}

export const Checkbox = ({ data, validationError }: CheckboxProps) => {
  const { name, boolDefaultValue, label } = readFragment(checkboxFragment, data)
  const [checked, setChecked] = useState(boolDefaultValue ?? undefined)
  const errorMessage = validationError?.data?.find((error) => error.field === name)?.message

  return (
    <div className="flex flex-col gap-100">
      <WFCheckbox
        checked={checked}
        onChange={(e) => setChecked(e.target.checked as boolean)}
        name={name ?? undefined}
        label={label}
      />
      {errorMessage && <div className="caption1 text-error">{errorMessage}</div>}
    </div>
  )
}
