import { TextareaField } from '@navinc/base-react-components/wayfinder'

import { FragmentOf, graphql, readFragment } from '../../../lib/cms.js'
import { ValidationError } from '../types.js'

export const textAreaFragment = graphql(/* GraphQL */ `
  fragment textAreaFragment on CmsTextAreaFormField {
    name
    label
    defaultValue
    required
  }
`)

export type TextAreaProps = {
  data: FragmentOf<typeof textAreaFragment>
  validationError?: ValidationError
}

export const TextArea = ({ data, validationError }: TextAreaProps) => {
  const { name, defaultValue, label, required } = readFragment(textAreaFragment, data)
  return (
    <TextareaField
      name={name ?? undefined}
      label={label}
      defaultValue={defaultValue ?? undefined}
      required={required ?? undefined}
      error={validationError?.data?.find((error) => error.field === name)?.message}
    />
  )
}
